import React from "react";


export default function PostHead(props) {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="col-lg-10">
                <img className="img-post" src={props.img} alt={props.alt}></img>
                <ul className="post-meta mt-3 mb-4">
                    <li className="d-inline-block mr-3">
                        <span className="fas fa-clock text-primary"></span>
                        <a className="ml-1" href="#">{props.date}</a>
                    </li>
                    <li className="d-inline-block">
                        <span className="fas fa-list-alt text-primary"></span>
                        <a className="ml-1" href={props.credLink}>{props.photoCred}</a>
                    </li>
                </ul>
                <hr />
                <div className="centered-container">
                    <h1 className="post-title">{props.title}</h1>
                </div>
            </div>
        </div >
    );
};