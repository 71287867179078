import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MonthlyFeature(props) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    };

    return (
        <div className="feats-carousel">
            <Slider {...settings}>
                {props.images.map((image, index) => (
                    <div key={index} className="feats__item">
                        <div className="single_feat">
                            <img className="" src={image.src} alt={image.alt}></img>
                            <div className="feat__content">
                                <div className="feat__title">{image.description}</div>
                                <a href={image.link} className="btn btn--block card__btn">{image.linkText}</a>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default MonthlyFeature;
