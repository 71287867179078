import React from 'react';
import Header from "../components/Header";
import koraLogo from "../assets/img/kora_logo_mast.jpg";
import wetaImg from "../assets/img/la_weta_texasjeep_2.jpg";
import cement_1 from "../assets/img/cement_1.jpg";
import concept_2 from "../assets/img/Koranex_world.jpg"
import HomeCard from "../components/HomeCard";
import CardInfoV2 from "../components/CardInfoV2";
import CardInfoV3 from "../components/CardInfoV3";
import FloatingText from "../components/FloatingText";
import Footer from "../components/Footer";
import MonthlyFeature from '../components/MonthlyFeature';
import featureImg from "../assets/img/22protacoFront.jpg";
import featureImg2 from "../assets/img/22ProtacoSideFront.jpg"
import featureImg3 from "../assets/img/22protacoFarFront.jpg"


function Home() {

    return (
        <div>
            <Header img={koraLogo} alt="kora logo in the mountains." />
            <FloatingText textTop="Mud, Rocks, and Skills: An Offroad Hub for Learning and Exploring"
                textBottom="Kora Offroad's Mud Talks is your digital compass for off-road material. Dive into our fundamentals of off-roading course, gear insights, and quick reads.
                            Uncover the secrets of customization and gear up for untamed landscapes." />
            <MonthlyFeature 
                images={[
                    { src: featureImg, alt: "22protaco vehicle. Tacoma from the front gray, modded.", description: "Congratulations to 22protaco, our September Follower of the Month! With a Tacoma that’s as clean as their commitment to adventure, 22protaco exemplifies the perfect blend of pride in their ride and passion for offroading. Thanks for setting such a high standard and inspiring us all!", link: "https://www.instagram.com/22protaco/", linkText: "22protaco" },
                    { src: featureImg2, alt: "22protaco tacoma, gray modded toyota.", description: "Congratulations to 22protaco, our September Follower of the Month! With a Tacoma that’s as clean as their commitment to adventure, 22protaco exemplifies the perfect blend of pride in their ride and passion for offroading. Thanks for setting such a high standard and inspiring us all!", link: "https://www.instagram.com/22protaco/", linkText: "22protaco" },
                    { src: featureImg3, alt: "Modded toyota tacoma gray from afar.", description: "Congratulations to 22protaco, our September Follower of the Month! With a Tacoma that’s as clean as their commitment to adventure, 22protaco exemplifies the perfect blend of pride in their ride and passion for offroading. Thanks for setting such a high standard and inspiring us all!", link: "https://www.instagram.com/22protaco/", linkText: "22protaco" }
                ]}
            />
            <HomeCard img={wetaImg}
                alt="White gladiator rubicon in a dirt trail."
                title="Offroad Fundamentals Courses"
                description="Uncover the thrill of off-roading in our concise Off-Road Fundamentals course. Dive into quick, engaging modules exploring the history, theory, and essential tips for navigating challenging terrains. From vehicle components to conquering tough landscapes, this course is your shortcut to mastering the off-road adventure. Get ready to rev up your knowledge and passion!"
                linkto="./courses"
                overlayText="ig: la_weta_texasjeep" />
            <CardInfoV2 img={cement_1}
                linkto='./blog'
                overlayText="ig: 801cement"
                alt="Black wrangler, soft-top, in a snowy trail in the mountains."
                title="Off-road Articles"
                description="Where the Dirt Talks, and We Listen! 
            Rev up your curiosity and kick dust in the face of ordinary with our blog that's as wild as a 4x4 tearing through a snowy trail. 
            We're not just here to spill the beans; we're here to sling the snow and serve up the sauciest offroad stories on this side of the internet." />
            <CardInfoV3 img={concept_2}
                alt="Image of sowftware application. Link takes you to the Koranex app landing page."
                title="Technology"
                description="Where Silicon Valley Meets the Offroad Trail! 
            Get ready to shift gears and dive deep into the tech-driven side of offroading with our mind-blowing exploration of software.
             It's not just about conquering rugged terrains; it's about doing it with the brains of a supercomputer and the finesse of a tech-savvy offroader."
                linkto="./koranex" />

            <Footer />
        </div>

    )
}

export default Home;