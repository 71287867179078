import React from "react";



export default function FloatingText(props) {
    return (
        <section className="intro-section" text-center id="about">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8">
                        <h2 className="text-white mb-2">{props.textTop}</h2>
                        <hr />
                        <p className="text-center-white">{props.textBottom}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
