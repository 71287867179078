import React from 'react';
import PropTypes from 'prop-types';

function CallToAction({ title, subtitle, ctaText, ctaLink }) {
    return (
        <div className="container text-center">
            <h2>{title}</h2>
            <p>{subtitle}</p>
            <a href={ctaLink} className="btn btn-primary btn-lg">{ctaText}</a>
        </div>
    );
}

CallToAction.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
    ctaLink: PropTypes.string.isRequired
};

export default CallToAction;
