


const moduleI = [
    {
        "id": 1,
        "title": "Lesson 1: The Off-Roading Time Capsule",
        "HeadImg": require("../img/kora_offroad_concept1.png").default,
        "date": "January 22, 2024",
        "description": "Welcome to the Dirt Side: Unearthing the Roots of Off-Roading",
        "img": require("../img/great_american_race.png").default,
        "content": [["", "Introduction", "Off-roading, a captivating adventure weaving through rugged terrains and challenging landscapes, has etched its thrilling narrative into the fabric of American recreational culture. This quick-read lesson aims to delve into the origins and evolution of off-roading in the United States, tracing its fascinating journey from humble beginnings to becoming a celebrated outdoor pursuit."],
        [require("../img/stuck_montello.png").default, "Early Explorations and Expeditions", "In the early 20th century, off-roading had its roots in exploratory journeys, with intrepid individuals seeking to traverse uncharted territories in automobiles. Expeditions like the Great American Desert Race in 1908 and the Transcontinental Motor Convoy in 1919 marked the dawn of off-road travel, showcasing the potential of vehicles beyond paved roads."],
        ["", "Military Influence", "The military played a pivotal role in shaping off-roading. World War II's Jeep, renowned for its off-road capabilities, became an iconic vehicle and sparked civilian interest in rugged, versatile automobiles."],
        [require("../img/military_jeep_soldiers.png").default, "The Off-Road Boom", "Rise of the 4x4 Enthusiast: Post-World War II, surplus military Jeeps became accessible to the public, leading to a surge in civilian interest in off-roading. Off-road clubs and events emerged, fostering a sense of community among 4x4 enthusiasts."],
        [require("../img/pexels-juan-montes-bronco.jpg").default, "The Advent of Off-Road Vehicles", "The 1960s witnessed the introduction of purpose-built off-road vehicles. The Ford Bronco (1966) and the Chevrolet K5 Blazer (1969) were among the pioneers catering to the growing off-road market."],
        ["", "Off-Roading Culture and Events", "Off-Roading as a Recreational Pursuit: Off-roading transitioned from utilitarian purposes to a recreational pursuit. Enthusiasts embraced the thrill of conquering challenging terrains purely for the love of adventure. More on races in the next lesson."],
        ["", "The Baja 1000 and Off-Road Racing", "The Baja 1000, inaugurated in 1967, became a cornerstone in off-road racing, drawing attention to the capabilities of off-road vehicles in extreme conditions."],
        [require("../img/offroad_Tech.jpg").default, "Evolution of Off-Roading Vehicles", "Technological Advancements: Technological innovations, such as the advent of four-wheel-drive systems, improved suspension systems, and enhanced tire technology, propelled off-roading vehicles to new heights of performance."],
        ["", "Diversity in Off-Roading Vehicles", "Over time, off-roading vehicles diversified to cater to different preferences. From the robust Jeep Wrangler to the luxurious Land Rover, manufacturers began offering an array of options."],
        [require("../img/colorado_mountains.jpg").default, "Legal and Environmental Considerations", "Land Access and Regulations: Off-roading faced challenges regarding land access and environmental impact, leading to the establishment of regulations and designated off-road areas."],
        ["", "Responsible Off-Roading", "The off-roading community embraced the principles of responsible recreation, emphasizing the importance of treading lightly and minimizing environmental impact."],
        ["", "Conclusion", "In tracing the origins of off-roading, we uncover a captivating journey that spans generations and continents. From the early 20th-century explorations and the influence of military vehicles to the surge of recreational off-roading and the invention of purpose-built off-road vehicles, the narrative is one of evolution and adaptation. Off-roading, with its roots deeply embedded in exploration and adventure, has evolved into a global phenomenon. Enthusiasts, bound by a shared love for challenging terrains and the thrill of the unknown, have contributed to the diverse and dynamic culture of off-roading we know today."]]
    },
    {
        "id": 2,
        "title": "Lesson 2: The Famous and Infamous Races",
        "HeadImg": require("../img/pexels-diego-rodriguez-baja.jpg").default,
        "date": "January 23, 2024",
        "description": "If you are not into the racing scene, that's okay. It's brownie points to be familiar with these races.",
        "img": require("../img/pexels-diego-rodriguez-baja.jpg").default,
        "content": [["", "1. The Baja 1000", "Where Dust is the Dress Code. A race that makes other off-road events look like Sunday drives to the grocery store.     Born in 1967, the Baja 1000 is the grandfather of off-road races, a rugged tradition that has seen its fair share of dusty tales. Initially a point-to-point race, it evolved into a loop course, taking racers through the challenging landscapes of the Baja California Peninsula. Challenges and Thrills: The course features treacherous desert stretches, rocky canyons, and unpredictable weather, making every Baja 1000 an unpredictable odyssey. Racers tackle the unforgiving terrain, battling not only competitors but also the harsh elements that define the essence of Baja."],
        [require("../img/dakar-rally.png").default, "2. Dakar Rally", "More Than Just a Really Long Drive. The race that dares you to ask, 'Why take the highway when you can conquer continents?' Its evolution, the gnarly stages, and the sheer global chaos it brings to the table will keep you on the edge. Originally known as the Paris-Dakar Rally, this off-road extravaganza was born in 1978 and spanned continents, challenging racers to conquer landscapes from Europe to Africa. The race has since migrated to South America, offering a different but equally daunting set of challenges. The Dakar Rally has transcended its role as a race, becoming a symbol of adventure and a rallying point for off-road enthusiasts worldwide."],
        [require("../img/koh-ultra4.png").default, "3. King of the Hammers", "Where Rocks and Roll Collide. Strap in for the King of the Hammers, where rock crawling meets high-speed desert racing, and every vehicle dreams of a starring role in its action. It's off-road's ultimate endurance challenge, and we're here to unravel the chaos that unfolds in the dusty canyons.     Established in 2007, King of the Hammers (KOH) is a unique blend of rock crawling and high-speed desert racing, a concoction that challenges the technical prowess of rigs, drivers, and their internal organs. Held in the Johnson Valley, California, it's the proving ground for Ultra4 racing, where the unforgiving rocks meet the relentless desert. The race demands a dual skill set – the precision of rock crawling through the formidable Hammer Trails and the flat-out speed required to conquer the open desert stretches."],
        [require("../img/mint-400.png").default, "4. The Mint 400", "Where the Dirt Holds the Deeds. A race that's as American as apple pie and as wild as a rodeo on rocket-fueled broncos.     Conceived in 1967, the Mint 400 is an off-road spectacle that embraces the American spirit of adventure and horsepower. Originally a loop race in the deserts of Nevada, it has evolved into a multi-lap race, testing the mettle of racers against a diverse array of challenges.     The Mint 400 is more than a race; it's a cultural touchstone, embedded in the American off-road ethos. It has attracted celebrities, off-road legends, and a dedicated fanbase, cementing its status as a classic American off-road tradition."]]
    },
    {
        "id": 3,
        "title": "Lesson 3: The Rise of Off-Road Clubs",
        "HeadImg": require("../img/pexels-tito-landcruiserLineup.jpg").default,
        "date": "January 25, 2024",
        "description": "Explore the contemporary off-road scene and discover the diverse array of off-road clubs shaping the culture today.",
        "img": require("../img/pexels-tito-landcruiserLineup.jpg").default,
        "content": [["", "Introduction", "This quick lesson is your backstage pass to the varied tribes of trail conquerors – from those who navigate rocks like off-road ninjas to the wanderers embracing the call of the open trail. So, lace up those boots, tune your engines, and get ready for an off-road journey where the trails are wild, and the clubs are even wilder. Let's rev up the fun, because in this lesson, the off-road clubs are calling, and your adventure awaits!"],
        ["", "1. Off-Road Enthusiast Clubs", "Off-road enthusiast clubs form the bedrock of the off-road community, creating a diverse and inclusive landscape where individuals with a shared love for off-roading come together. Ranging from broad, general off-road groups to niche clubs dedicated to specific vehicle types or terrains, these clubs play a pivotal role in fostering connections and shaping the off-road culture."],
        ["", " ", "These clubs act as hubs for exploration, where members share their off-road adventures, discuss vehicle modifications, and exchange valuable insights. The sense of camaraderie within these clubs goes beyond the trail, creating a community where enthusiasts connect on a personal level, forging lasting friendships united by a passion for off-roading."],
        ["", " ", "Off-road enthusiast clubs are dynamic organizers of events that range from local trail rides to large-scale off-road gatherings. These events not only provide members with opportunities to showcase their rigs and skills but also serve as platforms for learning and skill development. From charity events to technical workshops, these clubs contribute significantly to the holistic growth of the off-road community."],
        [require("../img/overland_gladiator.jpg").default, "2. Overland Adventure Clubs", "In the realm of overland adventure clubs, off-roaders embark on journeys that go beyond the conventional trail ride. These clubs celebrate the spirit of exploration, often involving extended trips that may span multiple days or even weeks. The ethos of overland adventure clubs lies in self-sufficiency, camping, and the immersive experience of being one with nature."],
        ["", " ", "Members of overland adventure clubs face unique challenges, from navigating remote terrains to ensuring they have the necessary resources for extended journeys. The emphasis is not only on conquering trails but also on the journey itself – a transformative experience that combines the thrill of off-roading with the serenity of the great outdoors."],
        ["", " ", "Camaraderie is at the heart of overland adventure clubs, as members share the challenges and triumphs of their journeys. Whether it's overcoming a tough trail or setting up camp in a breathtaking location, the shared experiences create bonds that extend beyond the trails, fostering a sense of kinship among members."],
        [require("../img/rock-crawl.png").default, "3. Rock Crawling and Trail Riding Clubs", "For enthusiasts who thrive on conquering technical terrains, rock crawling and trail riding clubs offer a specialized haven. These clubs focus on the intricate skill of navigating challenging obstacles, from steep rock faces to rugged trails. The community within these clubs shares a deep appreciation for the technical aspects of off-roading."],
        ["", " ", "Members of rock crawling and trail riding clubs often delve into specialized skills, mastering the art of reading terrains and executing precision maneuvers. Vehicle modifications become an integral part of this subculture, with rigs tailored for the demands of technical off-roading. Lift kits, specialized tires, and reinforced suspensions are common sights among the vehicles associated with these clubs."],
        ["", " ", "The events organized by these clubs are tailored to cater to the unique challenges of technical off-roading. Rock crawling competitions, trail ride challenges, and skill-building workshops are prevalent, providing enthusiasts with platforms to hone their abilities and showcase their modified rigs. These clubs contribute to the technical evolution of off-roading by pushing the boundaries of what is deemed drivable."],
        [require("../img/recovery-jeep.png").default, "4. The Heroes of Recovery - Off-Road Clubs", "In our exploration of off-road culture, let's delve into a unique subset of clubs that stand out as the unsung heroes of the off-road community – those dedicated to recovery. These specialized clubs focus on the critical mission of providing assistance and recovery support to off-roaders facing challenging situations on the trails. These recovery-focused clubs play a pivotal role in ensuring the safety and well-being of off-road enthusiasts. From winch operations and extraction techniques to equipped recovery rigs, these clubs are equipped with the expertise and tools needed to navigate and overcome challenging terrains."],
        ["", " ", "Recovery-focused clubs add a layer of resilience and preparedness to the rich tapestry of off-road culture. Their dedication to assisting others reinforces the ethos of community within the off-road landscape, showcasing that, together, off-roaders can overcome any obstacle on the trail."],
        ["", "Conclusion", "It's clear that off-road culture is a rich tapestry woven with diverse threads of passion, camaraderie, and a shared love for exploration. Whether you find your tribe in a specialized club or prefer the solitude of free-roaming, the off-road landscape offers an array of experiences waiting to be discovered. Our journey through this lesson is just a glimpse into the vast and ever-evolving world of off-road culture, inviting enthusiasts to continue exploring, connecting, and embracing the thrill of the untamed trails."]]
    }

]

export default moduleI;

