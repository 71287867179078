import React from "react";
import { Link } from "react-router-dom";


function HomeCard(props) {
    return (
        <div className="row gx-0 mb-4 mb-lg-5 align-items-center">
            <div className="col-xl-8 col-lg-7">
                <Link to={props.linkto}>
                    <img className="img-fluid mb-3 mb-lg-0" src={props.img} alt={props.alt} />
                </Link>
                <div className="overlay-text">
                    <p>{props.overlayText}</p>
                </div>
            </div>
            <div className="col-xl-4 col-lg-5">
                <div className="featured-text text-center text-lg-left">
                    <h3 className="text-title">{props.title}</h3>
                    <hr />
                    <p className="text-white-50 mb-0">{props.description}</p>
                </div>
            </div>
        </div>
    )
}

export default HomeCard