import React from 'react';
import BlogCard from '../components/BlogCard';
import PostData from '../assets/blogposts/posts'
import Footer from '../components/Footer';
import HeaderBlog from '../components/HeaderBlog';
import KoraMountain from '../assets/img/kora_offroad_mountain.png'
import FloatingText from '../components/FloatingText';
import { Link } from 'react-router-dom';

const Blog = () => (
    <div>
        <HeaderBlog img={KoraMountain}
            alt="Kora Offroad Logo on the mountain."
            title="Explore Kora" />
        <FloatingText textTop="Kora Offroad's Mud Talks"
            textBottom="Unearth Off-Roading Chronicles" />

        {PostData.map((post) => (
            <BlogCard
                key={post.id}
                id={post.id}
                title={post.title}
                img={post.img}
                alt={post.alt}
                content={post.content}
                description={post.description}
                date={post.date}
            />
        ))}
        <Footer />
    </div>
);

export default Blog;