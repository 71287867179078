import React from 'react';
import { Link } from 'react-router-dom';

export default function BlogCard(props) {
    console.log(props.img);
    return (
        <article className="postcard dark blue">
            <Link to={`/blog/${props.id}`} className="postcard__img_link">
                <img className="postcard__img" src={props.img} alt={props.alt} />
            </Link>
            <div className="postcard__text">
                <h1 className="postcard__title blue">
                    <Link to={`/blog/${props.id}`}>{props.title}</Link>
                </h1>
                <div className="postcard__subtitle small">
                    <time dateTime={props.date}>
                        <i className="fas fa-calendar-alt mr-2"></i>
                        {props.date}
                    </time>
                </div>
                <div className="postcard__bar"></div>
                <div className="postcard__preview-txt">{props.description}</div>
                <ul className="postcard__tagbox">
                    <li className="tag__item play blue">
                        <Link to={`/blog/${props.id}`}>
                            <i className="fas fa-play mr-2"></i>Read More
                        </Link>
                    </li>
                </ul>
            </div>
        </article>
    );
}