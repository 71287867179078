import React from "react";
import ContentCard from "./ContentCard";



export default function CourseContent(props) {
    const content = props.content;

    return (
        <div>
            {content.map((subArray, index) => (
                <div>
                    <ContentCard key={index} subArray={subArray} />
                </div>
            ))}
        </div>
    );
};
