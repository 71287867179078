import React from 'react';


export default function ContentCard(props) {
    const subArray = props.subArray;

    return (
        <article className="content-card">
            <div className="card__content">
                {subArray[0] && <img src={subArray[0]} alt="test alt" />}
                <h2>{subArray[1]}</h2>
                <hr />
                <p>{subArray[2]}</p>
            </div>
        </article>
    );
};