import React from "react";
import { useParams } from 'react-router-dom';
import courses from '../assets/courses/courseContent';
import Footer from "./Footer";
import PostHead from "./PostHead";
import CourseContent from "./CourseContent";
import { Link } from "react-router-dom";


function Course() {
    const { id } = useParams();
    console.log(id);
    console.log(courses);

    const courseDataIdx = parseInt(id) - 1;
    const courseData = courses[courseDataIdx];
    console.log(courseData);
    return (
        <div>
            <PostHead img={courseData.img} alt={courseData.alt} date={courseData.date} title={courseData.title} />
            <hr />
            <CourseContent content={courseData.content} />
            <div className="extra-pad">
                <Link to="/courses" className="button-back">Back to Modules</Link>
            </div>
            <Footer />
        </div >
    );
}

export default Course;
