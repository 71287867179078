import React from "react";


export default function HeaderBlog(props) {
    console.log(props.img)
    return (
        <header className="bloghead">
            <div className="overlay"></div>
            <img src={props.img} alt={props.alt} />
            <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                <div className="d-flex justify-content-center">
                    <div className="text-center">
                        <h1 className="mx-auto my-0 text-uppercase">{props.title}</h1>
                    </div>
                </div>
            </div>
        </header>
    )
}

