import React from 'react';
import Footer from '../components/Footer';
import HeaderBlog from '../components/HeaderBlog';
import headImage from '../assets/img/la_Weta_galdiator_1.jpg';
import CourseContent from '../assets/courses/courseContent.js';
import ModuleCarousel from '../components/ModuleCarousel.jsx';
import FloatingText from '../components/FloatingText.jsx';


const Blog = () => (
    <div>
        <HeaderBlog img={headImage} alt="Kora Offroad Logo on the mountain." title="Learn Kora" />
        <FloatingText textTop="Learn the fundamentals of offroading with our quick-read modules." textBottom="The core objective of our fundamentals course is to furnish off-roaders with foundational knowledge and essential concepts." />
        <hr />
        <h2 className='general-text'>Module I: Introduction to Off-Roading</h2>
        <ModuleCarousel slides={CourseContent} />
        <hr />
        <h2 style={{ textAlign: 'center', fontFamily: 'Verdana' }}>More Modules Coming Soon...</h2>
        <Footer />
    </div >
);

export default Blog;