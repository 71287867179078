
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import CallToAction from '../components/CallToAction';
import appScreenshot from '../assets/img/Koranex_GPS.jpg';
import offroadBackground from '../assets/img/world_app_modified.jpg';
import routesImage from '../assets/img/Koranex_route_sat.jpg'
import geospatialTools from '../assets/img/Koranex_garage.jpg';
import worldImg from '../assets/img/Koranex_Mountains.jpg'
import CardInfoV4 from '../components/CardInfoV4';
import CardInfoV3 from '../components/CardInfoV3';
import GradientBlock from '../components/GradientBlock';
import FloatingText from '../components/FloatingText';
import MonthlyFeature from '../components/MonthlyFeature';
import opZOne from '../assets/img/Koranex_planning.jpg'


function LandingPage() {
    return (
        <div>
            <HeroSection
                backgroundImage={offroadBackground}
                title="The Menu For Your Off-Road Adventures"
            />
            <FloatingText textTop={"The Menu For Your Off-Road Adventures"} textBottom={"Koranex, your all-in-one geographical tool designed for off-road enthusiasts and off-road event planners. As the debut app from Kora Offroad, Koranex is more than just a tool—it's the foundation of an ambitious vision."}/>
            <CardInfoV4 img={routesImage}
                alt="Black wrangler, soft-top, in a snowy trail in the mountains."
                title="Plan and Conquer Events"
                description="Planning an off-road event has never been easier. With Koranex, you can draw areas of operation, assign roles, manage tasks, and save places of interest with precision geomarkers." />
            <CardInfoV3 img={appScreenshot}
                alt="Image of sowftware application. Link takes you to the Koranex app landing page."
                title="Live GPS Tracking: Navigate with Confidence"
                description="Stay connected to your adventure with Koranex's real-time GPS tracking. Whether you're leading a convoy or exploring solo, our live tracking feature ensures you always know your exact location on the trail."
                 />
            <CardInfoV4 img={geospatialTools}
                alt="Black wrangler, soft-top, in a snowy trail in the mountains."
                title="Save and Customize Your Rides"
                description="Your garage, your rules. Koranex allows you to save and manage all your vehicles, including every custom modification. From superchargers to lift kits, document what, when, and where your mods are getting attached." />
            <CardInfoV3 img={worldImg}
                alt="Image of sowftware application. Link takes you to the Koranex app landing page."
                title="Explore a 3D World"
                description="Drop pins and save georeferenced locations on our interactive 3D world map. Scout new trails, mark your favorite spots, and always know where your next adventure will take you."/>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '10vh', // Full viewport height
                margin: '0',
            }}>
                <h4 style={{
                    color: '#F0A500', 
                    fontSize: '30px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    letterSpacing: '2px',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    fontFamily: 'Arial, sans-serif',
                    textAlign: 'center', // Center text within the h4 element
                }}>
                    Coming Soon
                </h4>
            </div>
            <CallToAction
                title="Our App is still undergoing development and field testing."
                subtitle="Subscribe to our newsletter to recieve the latest updates."
                ctaText="Navigate to Subscribe Page"
                ctaLink="https://koraoffroad.com/pages/contact"
            />
            <MonthlyFeature img={opZOne}
                alt="Screenshot of app drawing operational zone on map."
                description="Our software is inspired and developed by veterans. We appreciate all service members. Thank you for all you do."
                linkText="Follow us on Instagram"
                link="https://www.instagram.com/koraoffroad"
            />
            <Footer />
        </div>
    );
}

export default LandingPage;
