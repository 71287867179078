import PropTypes from 'prop-types';

function HeroSection({ backgroundImage, title, subtitle, ctaText, ctaLink }) {
    const heroStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        height: '50vh', // Full viewport height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        fontFamily: "'Cinzel', serif", // Use Roboto as the modern font
    };

    const titleStyle = {
        fontSize: '80px', // Large font size
        fontWeight: 'bold', // Bold font weight, // Futuristic font
        margin: 0, // Remove default margin
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        padding: '10px 20px', // Padding around the text
        display: 'inline-block', // To make the background fit the text
        borderRadius: '10px', // Slightly rounded corners
        textAlign: 'center', // Center text within the h1 element
    };

    return (
        <div style={heroStyle}>
            <h1 style={titleStyle}>KORANEX</h1>
        </div>
    );
}

HeroSection.propTypes = {
    backgroundImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
    ctaLink: PropTypes.string.isRequired,
};

export default HeroSection;
