import React from "react";
import { Link } from "react-router-dom";

function CardInfoV3(props) {
    return (
        <div class="row gx-0 justify-content-center">
            <div class="col-lg-6">
                <Link to={props.linkto}>
                    <img class="img-fluid" src={props.img} alt={props.alt} />
                </Link>
            </div>
            <div class="col-lg-6 order-lg-first">
                <div class="bg-black text-center h-100 project">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-right">
                            <h4 class="text-white">{props.title}</h4>
                            <hr />
                            <p class="mb-0 text-white-50">{props.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CardInfoV3;
