import React from "react";

function GradientBlock(props) {
    return (
        <div className="gradient-block"></div>
    )
};

export default GradientBlock;


