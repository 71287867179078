import React from "react";
import { Link } from "react-router-dom";
import KoraLogo from "../assets/img/kora_logo.png";

export default function Footer() {
    return (
        <div>
            <div class="pg-footer">
                <footer class="footer">
                    <svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
                        <path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
                    </svg>
                    <div class="footer-content">
                        <div class="footer-content-column">
                            <div class="footer-logo">
                                <a class="footer-logo-link" href="#">
                                    <img src={KoraLogo} alt="Kora Offroad Logo"></img>
                                </a>
                            </div>

                        </div>
                        <div class="footer-content-column">
                            <div class="footer-menu">
                                <h2 class="footer-menu-name"> Company</h2>
                                <ul id="menu-company" class="footer-menu-list">
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <Link to="https://koraoffroad.com/pages/about-kora-offroad">About</Link>
                                    </li>
                                    <li class="menu-item menu-item-type-taxonomy menu-item-object-category">
                                        <Link to="./">Home</Link>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <Link to="https://koraoffroad.com/collections/all">Products</Link>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-menu">
                                <h2 class="footer-menu-name"> Legal</h2>
                                <ul id="menu-legal" class="footer-menu-list">
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                                        <Link to="https://koraoffroad.com/pages/privacy-policy">Privacy Notice</Link>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <Link to="https://koraoffroad.com/pages/return-policy">Return Policy</Link>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <Link to="#">Terms of Use</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-content-column">
                            <div class="footer-menu">
                                <h2 class="footer-menu-name"> Quick Links</h2>
                                <ul id="menu-quick-links" class="footer-menu-list">
                                    <li class="menu-item menu-item-type-custom menu-item-object-custom">
                                        <Link target="_blank" rel="noopener noreferrer" to="./courses">Courses</Link>
                                    </li>
                                    <li class="menu-item menu-item-type-custom menu-item-object-custom">
                                        <Link target="_blank" rel="noopener noreferrer" to="./blog">Blog</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-content-column">
                            <div>
                                <div class="flex-center" style={{ 'alignContent': 'center' }}>
                                    <Link to="https://www.facebook.com/profile.php?id=100092837872206&mibextid=ZbWKwL">
                                        <i class="fa fa-facebook fa-4x icon-3d"></i>
                                    </Link>
                                    <Link to="https://www.instagram.com/koraoffroad/">
                                        <i class="fa fa-instagram fa-4x icon-3d"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="footer-copyright">
                        <div class="footer-copyright-wrapper">
                            <p class="footer-copyright-text">
                                <a class="footer-copyright-link" href="#" target="_self"> ©2024. | Kora Offroad | All rights reserved. </a>
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}