import React from "react";
import { Link } from "react-router-dom";


function CardInfoV2(props) {
    return (
        <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div className="col-lg-6">
                <Link to={props.linkto}>
                    <img className="img-fluid" src={props.img} alt={props.alt} />
                </Link>
                <div className="overlay-text">
                    <p>{props.overlayText}</p>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="bg-black text-center h-100 project">
                    <div className="d-flex h-100">
                        <div className="project-text w-100 my-auto text-center text-lg-left">
                            <h4 className="text-title">{props.title}</h4>
                            <hr />
                            <p className="mb-0 text-white-50">{props.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


export default CardInfoV2;