import React from 'react';
import { Link } from 'react-router-dom';
import boxIcon from '../assets/img/box.png'; // Import the box.png image

function Navbar() {
    const toggleFunction = () => {
        var x = document.getElementById("navDemo");
        if (x.className.indexOf("w3-show") === -1) {
            x.className += " w3-show";
        } else {
            x.className = x.className.replace(" w3-show", "");
        }
    };

    return (
        <div>
            <div className="w3-top">
                <div className="w3-bar" id="myNavbar">
                    <Link to="/" className="w3-bar-item w3-button">HOME</Link>
                    <Link to="/courses" className="w3-bar-item w3-button w3-hide-small"><i className="fa fa-user"></i> COURSES</Link>
                    <Link to="/blog" className="w3-bar-item w3-button w3-hide-small"><i className="fa fa-th"></i> BLOG</Link>
                    <a href="https://koraoffroad.com/collections/all" className="w3-bar-item w3-button w3-hide-small">
                        <i className="fa fa-shopping-cart"></i> PRODUCTS
                    </a>                    
                    <Link to="/koranex" className="w3-bar-item w3-button">
                        <i className="fa fa-globe"></i> KORANEX
                    </Link>   
        
                    {/* Add more links as needed */}
                    <a className="w3-bar-item w3-button w3-hover-black w3-hide-medium w3-hide-large w3-right" onClick={toggleFunction} title="Toggle Navigation Menu">
                        <i className="fa fa-bars"></i>
                    </a>
                </div>
                <div id="navDemo" className="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium">
                    <Link to="/courses" className="w3-bar-item w3-button" onClick={toggleFunction}>COURSES</Link>
                    <Link to="/blog" className="w3-bar-item w3-button" onClick={toggleFunction}>BLOG</Link>
                    <a href="https://koraoffroad.com/" className="w3-bar-item w3-button" onClick={toggleFunction}>PRODUCTS</a>
                    <Link to="/koranex" className="w3-bar-item w3-button" onClick={toggleFunction}>KORANEX</Link>
                    {/* Add more links as needed */}
                </div>
            </div>
        </div>
    );
}

export default Navbar;