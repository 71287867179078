const PostData = [
    {
        "id": 1,
        "title": "Kora Offroad's Mud Talks",
        "date": "January 27, 2024",
        "img": require("../img/wrangler_1.jpg").default,
        "photoCred": "ig: daniel__ls",
        "credLink": "https://www.instagram.com/daniel__ls/",
        "alt": "2013 Jeep wrangler soft-top in the snow.",
        "description": "Mud Talks is a rebranding of our Kora Offroad Blog with it's own home. Mud Talks is going to be a hub for all things Kora Offroad.",
        "content": [["", "Welcome to Mud Talks, your gritty and no-nonsense companion in the dynamic world of off-roading. We're not about fancy fluff; we're here to get down and dirty, tackling the real challenges and celebrating the thrill of off-road adventures."],
        ["", "In the ever-evolving realm of off-roading, Mud Talks serves up a hearty dose of everything you need to know. Buckle up for a journey through the latest and greatest in off-road technology, where we break down the complex innovations and keep you ahead of the curve."],
        ["", "But Mud Talks is not just about gears and gadgets – we're all about the nitty-gritty tips that make a real difference. From conquering tricky terrains to optimizing your off-road experience, we've got the practical insights that every enthusiast craves."],
        ["", "And because off-roading is a living, breathing culture, Mud Talks doesn't shy away from the pulse of the community. Stay tuned for the freshest news and updates, connecting you with the heartbeat of the off-road world."],
        ["", "No frills, just spills – because the most memorable stories are born in the mud. Join us as we navigate the rugged landscape of off-road exploration, where mud meets real talk. Whether you're a seasoned trailblazer or a curious newcomer, Mud Talks is your reliable pit stop for the authentic, unfiltered, and exhilarating world of off-roading."]]
    }
    , {
        "id": 2,
        "title": "On Human's Off-Roading",
        "date": "January 28, 2024",
        "img": require("../img/la_weta_gladiator3.png").default,
        "alt": "Gladiator flexing on the dirt trail",
        "photoCred": "ig: la_weta_texasjeep",
        "credLink": "https://www.instagram.com/la_weta_texasjeep/",
        "description": "Amongst the array of extravagant hobbies vying for attention, what draws humans toward the rugged allure of off-roading? Invest a brief span of your reading time, and let me articulate the compelling reasons that set this exhilarating pursuit apart from its high-cost counterparts.",
        "content": [["", "Human evolution, a journey spanning millions of years, culminated in the emergence of Homo Sapiens. From bipedalism and brain enlargement to the development of complex societies, this evolutionary tale laid the groundwork for our species. Fast-forward to 300,000 years ago, and Homo sapiens ushered in an era of agriculture, settled communities, and diverse cultures. Today, we find ourselves traversing the Earth in metal boxes with wheels, exploring the intricate tapestry of our evolutionary history."],
        ["", "Having come this far as a species, the question of why humans engage in particular activities often leads to a contemplation of our purpose—a timeless inquiry that occasionally nudges us towards clichés. To avoid the rabbit hole, let's simplify and explore a couple of compelling reasons why off-roading captures our collective imagination, all rooted in breaking free from the monotony of our daily routines."],
        ["1. Master the Mayhem, Embrace the Uncharted", "Embarking on the mysteries of our world is a quintessential part of our human spirit. Whether it's the allure of encountering Sasquatch in the Colorado mountain range or chasing UFOs (or UAPs, as they're known nowadays) in a New Mexico desert, the thrill lies in venturing far beyond the realms of civilization. While the likelihood of stumbling upon such phenomena is minimal, the real magic happens when our rugged rigs become the means to explore the hidden corners of mountains and forests. Off-roading thrives on the unpredictability inherent in navigating rocky trails, conquering mud pits, and turning every bend with the promise of adventure. However, this thrill comes at a cost – the unpredictability that makes off-roading exhilarating is also the culprit behind the expenses associated with this off-road passion. When your wheels leave the concrete behind, the lack of predictability about the next challenge your rig will face – whether it's a tie-rod giving out during a rocky yoga session or encountering unforeseen obstacles – underscores the need for thorough preparation, inevitably accompanied by a price tag."],
        ["2. Nature, Unveiled and Unapologetic", "In the evolving tapestry of time, our contemporary cityscapes may be hailed as marvels by future civilizations, while others might fade into the archives of the less remarkable.No offense, but I hail from the latter category.However, in this narrative, nature emerges as the unsung hero.Off - roading becomes the gateway to experience nature in its unfiltered grandeur – unveiling intricate trails, panoramic vistas, and landscapes so captivating they're deemed Instagram-worthy. A subtle reminder: in the age of social media, if you don't post it on Instagram, did the adventure truly happen?, Don't worry, I am aware that is a sh** joke. The true measure of the experience is the joy it brings, whether you capture it digitally or revel in the moment. Consider this: have you ever heard someone declare, 'This is ugly', while standing amidst the breathtaking beauty of Yosemite in California? The inherent connection to nature runs deep within us all. This connection may very well explain the magnetic allure of fully upgraded off-roading rigs, evoking an immediate association with the untamed wilderness. It's a primal connection that harks back to our origins in the wild – a nostalgic reminder of our innate selves."],
        ["", "Acknowledging that nature isn't universally embraced stems, in my opinion, from the societal constructs that dictate our comfort zones. Our environments are meticulously crafted for comfort, and it's this comfort that becomes ingrained in our habits. Yet, beneath the layers of societal conditioning lies our biological essence. Remove the trappings of cityscapes, and the biological entity within us strives to survive, toilet paper or not. This philosophical tangent underscores a fundamental truth: a well-equipped off-roading rig opens avenues to explore nature beyond the confines of accessible hikes. It's an invitation to rediscover the untamed landscapes that resonate with our primal instincts, a journey that transcends the comforts we've grown accustomed to in our urban havens."],
        ["3. Squad Goals", "Off-Road Edition: Beyond individual pursuits, humans are inherently social creatures. Off-roading, with its communal aspects, provides a platform for like-minded individuals to come together. Whether joining local off-road clubs or embarking on group expeditions, the shared passion for conquering challenging terrains fosters unbreakable bonds. The camaraderie formed on the trails extends beyond the hobby, creating a community of enthusiasts who not only share interests but also the inevitable financial strains associated with their adventurous pursuits."],
        ["4. Think On Your Feet, Literally", "Engaging in off-roading transcends the mere act of driving; it's an immersive experience that demands an agile mind and quick reflexes. Navigating unpredictable terrains isn't just a test of your vehicle's capabilities; it's a mental workout that hones your ability to adapt on the fly. Picture this: you're halfway through a trail when the terrain unexpectedly shifts, presenting a challenge you hadn't anticipated. In that moment, you become a strategist, assessing the situation, choosing the optimal path, and maneuvering your vehicle with precision. The beauty of thinking on your feet in the off-road realm is not just about overcoming obstacles; it's about transforming challenges into opportunities for personal growth. As you navigate the unexpected, you develop a mental resilience that extends beyond the trails, enriching your everyday life with a newfound ability to tackle whatever hurdles come your way."],
        ["5. Unleash Your Inner Maverick", "Off-roading isn't a hobby; it's a rebellion against the mundane, a declaration of independence from the confines of conventional living. It provides a canvas for enthusiasts to unleash their inner maverick, to tap into a primal, untamed spirit that often lies dormant in the routines of modern life. Whether you're roaring through challenging trails in a rugged 4x4 or gracefully navigating diverse landscapes on an ATV, off-roading becomes an expression of individuality, a manifestation of your distinctive approach to adventure."],
        ["Final Words", "In the world of off-roading, conformity takes a back seat as you forge your path through uncharted territories. The rugged trails become a metaphorical representation of life's unpredictability, and your vehicle becomes the vessel through which you navigate the chaos with flair and style. There's an undeniable sense of liberation that comes with steering your own course, making decisions on the fly, and conquering challenges in your unique way."]]
    }
]


export default PostData;