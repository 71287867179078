import React from 'react';
import backgroundImg from '../assets/img/kora_offroad_concept2.png';
import Footer from '../components/Footer';
import KoraLogo from '../assets/img/kora_logo.png';


export default function Register() {
    const backgroundStyle = {
        background: `url(${backgroundImg})`,  // Set the image as the background
        backgroundSize: 'cover',  // Cover the entire container
        backgroundPosition: 'center',  // Center the background image
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <html-login>
            <body-login>
                <div className='align' style={backgroundStyle}>
                    <div className="grid align__item">
                        <img className="login-img" src={KoraLogo} alt="Kora Offroad logo in white"></img>
                        <div className="register">
                            <h2>Sign Up</h2>

                            <form action="" method="post" className="form">

                                <div className="form__field">
                                    <input id="username" type="text" placeholder="Username" maxLength={"30"} required></input>
                                </div>

                                <div className="form__field">
                                    <input id="email" type="email" placeholder="Email" maxLength={"50"} required></input>
                                </div>

                                <div className="form__field">
                                    <input id="password" type="password" placeholder="Password" maxLength={"25"} required></input>
                                </div>

                                <div className="form__field">
                                    <input type="submit" value="Login"></input>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
                <Footer />
            </body-login>
        </html-login>

    )
}