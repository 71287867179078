import React from "react";
import { useParams } from 'react-router-dom';
import posts from '../assets/blogposts/posts';
import Footer from "./Footer";
import PostHead from "./PostHead";
import PostContent from "./PostContent";



function Post() {
    const { id } = useParams();

    const postDataIdx = parseInt(id) - 1;
    const postData = posts[postDataIdx];
    return (
        <div>
            <PostHead img={postData.img} alt={postData.alt} date={postData.date} title={postData.title} photoCred={postData.photoCred} credLink={postData.credLink} />
            <hr />
            <PostContent content={postData.content} />
            <Footer />
        </div >
    );
}

export default Post;
