import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function handleButtonClick() {
    console.log("test");
}

const ModuleCarousel = ({ slides }) => {

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    return (
        <div className="carousel-container">
            <div className="slide">
                <Link to={`/courses/${slides[currentSlide].id}`} >
                    <img src={slides[currentSlide].img} alt={slides[currentSlide].title} />
                </Link>
                <div className="text-container">
                    <h2>{slides[currentSlide].title}</h2>
                    <p>{slides[currentSlide].description}</p>
                </div>
                <div className="arrow-buttons">
                    <button onClick={prevSlide} style={{ 'background': 'rgba(0,0,0,0)', 'border': 'none' }}>
                        <svg width="60" height="50" xmlns="http://www.w3.org/2000/svg">
                            <polygon points="20,25 60,0 40,25 60,50" fill="white" transform="scaleX(-1)" />
                        </svg>
                    </button>
                    {/* <button className="arrow right" onClick={nextSlide}>Next</button> */}
                    <button onClick={nextSlide} style={{ 'background': 'rgba(0,0,0,0)', 'border': 'none' }}>
                        <svg width="60" height="50" xmlns="http://www.w3.org/2000/svg">
                            <polygon points="40,25 0,0 20,25 0,50 " fill="white" transform="scaleX(-1)" />
                        </svg>
                    </button>
                </div>
            </div>
        </div >
    );
};


export default ModuleCarousel;