import React from "react";
import koraLogoMast from '../assets/img/kora_logo_mast.jpg'; // Import the image
import { Link } from "react-router-dom";

function Header(props) {
    return (
        <header className="masthead" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 75%, #000 100%), url(${koraLogoMast})` }}>
            <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                <div className="d-flex justify-content-center">
                    <div className="text-center">
                        <h1 className="mx-auto my-0 text-uppercase"></h1>
                        <Link className='glowing-btn' to="./courses"><span className='glowing-txt'>LEARN</span></Link>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;
