import React from "react";
import { Link } from "react-router-dom";


export default function PostContent(props) {
    const content = props.content;

    return (
        <div className="text-blog-container">
            {content.map((paragraphData, index) => (
                <div >
                    <h2 className="post-title" style={{ 'paddingLeft': '1rem' }}>{paragraphData[0]}</h2>
                    <p className="text-post-white" key={index}>{paragraphData[1]}</p>
                </div>
            ))}

            <div className="extra-pad">
                <Link to="/blog" className="button-back">Back to blog</Link>
            </div>
        </div>
    );
};
